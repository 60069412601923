@import './base/variable.styl';

.contact-qrcode-wrap {
  margin-right: 50px;

  .our-qrcode-wrap {
    width: 99px;
    height: 99px;

    .our-qrcode-img {
      width: 100%;
      height: 100%;
    }

    .content {
      font-size: 12px;
      text-align: center;
    }
  }
}

.our-qrcode {
  position: fixed;
  right: -1px;
  bottom: 44px;
  z-index: 999;
  display: flex;
  transition: all 0.3s;
  opacity: 1;

  &.open {
    right: -10rem !important;
    opacity: 0.3;
  }

  .contrl-left {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
    height: 28px;
    width: 28px;
    position: relative;
    border: 1px solid #F3F3F3;
    border-right: 0;
    transform: translateX(1px);

    &.open {
      height: 12rem;
    }

    &:before {
      content: '';
      position: absolute;
      width: 200%;
      height: 200%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .arrow {
      color: #666;
      font-size: 1.5em;
      font-weight: 300;
      transform-origin: center;
      line-height: 0;
    }
  }

  .our-qrcode-wrap {
    background: #fff;
    padding: 8px;
    width: 10rem;
    height: 12rem;
    border: 1px solid #F3F3F3;
    border-right: 0;

    .our-qrcode-img {
      width: 100%;
    }

    .content {
      text-align: center;
      color: #2D313E;
      font-size: 12px;
      letter-spacing: -1px;
    }
  }
}

.js-loading {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

/* 控制整个滚动条 */
::-webkit-scrollbar {
  width: 0;
}

.js-fadeInUp {
  opacity: 0;
}

.body-overflow-hidden {
  height: 100vh;
  overflow: hidden;
}

.swiper-button-prev, .swiper-button-next {
  height: 100% !important;
  width: 40px !important;
  top: 0 !important;
  margin-top: 0 !important;
  transform: scale(0.8) !important;
}

.js-preview-img-bc.multi .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: -37px;
}

.js-preview-img-bc.multi .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: -38.2px;
}

.js-preview-img-bc.multi {
  .m-play-wrap {
    width: 200px;
    height: 40px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    margin: 0 20px 10px 0;
    display: block;
    font-size: 16px;
    position: relative;
    z-index: 2;

    .dropdown-qrcode-wrap {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      width: 200px;
      padding: 10px;
      background: #fff;
      height: 200px;
      display: none;
      z-index: 9;

      .dropdown-qrcode-img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .is-play-wrap {
    position: absolute;
    bottom: 30px;
    right: -3.3rem;
    color: #fff;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    .qrcode-max-wrap {
      width: 140px;
      height: 140px;
      position: relative;
      display: flex;
      justify-content: flex-end;

      .qrcode-wrap, .qrcode-wrap-m {
        width: 140px;
        height: 140px;
        padding: 10px;
        background: #fff;
      }

      .admin-qrcode {
        width: 140px;
        height: 140px;
        padding: 10px;
        background: #fff;

        .admin-qrcode-img {
          width: 100%;
          height: 100%;
        }
      }

      .qr-icon {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .game-name {
      text-align: right;
      font-size: 14px;
      margin-top: 3px;
    }

    .game-desc {
      text-align: right;
      font-size: 12px;
      opacity: 0.6;
      margin: 3px 0 10px;
    }

    .go-look {
      font-size: 14px;
      cursor: pointer;
      width: 120px;
      height: 2rem;
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid #fff;
      color: rgba(255, 255, 255, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .preview-img-wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .preview-img-box {
      flex: 1;

      .swiper-container-multi-img {
        max-height: 100%;
        height: calc(100vh - 4.2rem - 50px);

        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .multi-img-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;

        .multi-img {
          max-width: 98%;
          max-height: 100%;
          width: auto !important;
          height: auto !important;

          &.active {
            position: absolute;
            left: 50%;
            top: 0;
            width: 98%;
            max-height: unset;
            overflow: scroll;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) {
  .js-preview-img-bc.multi {
    .m-play-wrap {
      visibility: hidden !important;
    }
  }

  .our-qrcode {
    .contrl-left .arrow {
      transform: scaleY(1.8) rotate(0deg) translateY(-1px);
      font-size: 1em;
    }
  }
}

@media screen and (max-width: 1200px) {
  .our-qrcode {
    right: -1px;

    .contrl-left .arrow {
      transform: scaleY(1.8) rotate(180deg) translateY(-1.2px);
    }
  }

  .contact-qrcode-wrap {
    margin-right: 25px;
  }

  .js-preview-img-bc.multi {
    section {
      .demo-show {
        height: 25vw;
      }
    }

    .m-play-wrap {
      visibility: visible;
    }

    .is-play-wrap {
      visibility: hidden !important;
    }
  }

  .js-preview-img-bc .preview-img-wrapper {
    padding-top: 2rem;
  }

  .js-preview-img-bc .preview-img-wrapper .close {
    top: 2rem;
  }
}

.js-preview-img-bc {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999999;
  display: none;
  overflow-y: auto;

  .m-play-wrap {
    transition: all 0.01s;

    &:hover {
      opacity: 0.6;
    }

    &:active {
      opacity: 0.8;
    }
  }

  .go-look {
    transition: all 0.01s;

    &:hover {
      opacity: 0.6;
    }

    &:active {
      opacity: 0.8;
    }
  }

  .preview-img-wrapper {
    position: relative;
    width: 78%;
    margin: 0 auto 0;
    padding: 4rem 0 0 0;

    .swiper-container-multi-img {
      overflow: hidden;
    }

    .multi-img-box {
      .multi-img {
        width: 100%;
      }
    }

    .preview-img-box {
      width: 100%;

      .img {
        width: 100%;
        border-radius: 4px;
      }
    }

    .close, .expand {
      position: absolute;
      right: -3.3rem;
      width: 40px;
      height: 40px;
      cursor: pointer;
      z-index: 998;
    }

    .close {
      top: 4rem;
    }

    .expand {
      top: 7rem;
    }
  }
}

.js-preview-img-bc.single {
  .preview-img-wrapper {
    height: calc(100vh - 10px);

    // overflow-y auto
    .preview-img-box {
      height: 100%;
      overflow-y: auto;
      font-size: 0;
    }

    .is-play-wrap {
      position: fixed;
      right: 7rem;
      bottom: 2rem;
    }
  }
}

@media screen and (min-width: 1201px) {
  .js-preview-img-bc.single {
    .preview-img-wrapper {
      width: 66%;
    }
  }

  .js-preview-img-bc.single {
    .m-play-wrap {
      display: none !important;
    }
  }
}

@media screen and (max-width: 1200px) {
  .js-preview-img-bc.single {
    .preview-img-wrapper {
      width: 80% !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .close, .expand {
    transform: scale(0.7);
    transform-origin: top right;
    right: -2.8rem !important;
  }

  .m-play-wrap {
    transform: scale(0.7);
    transform-origin: top right;
    margin-right: 20px !important;
  }

  .expand {
    top: 6rem !important;
  }

  .js-preview-img-bc.single {
    .close {
      right: -3rem !important;
    }
  }

  .js-preview-img-bc.single .expand {
    right: -3rem !important;
  }
}

#fp-nav {
  display: block !important;
}

#main-content {
  .section {
    padding-top: 3rem;
  }
}

.swiper-button-next, .swiper-button-prev {
  transform: scale(0.5);
}

[data-anchor = 'firstPage'] {
  &.section {
    padding-top: 0 !important;
  }

  overflow: hidden;

  .m-logo {
    margin: 1rem 1.5rem 0;
    transform: scale(0.7);
    transform-origin: left top;
    position: relative;
  }

  .bg-canvas {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .call-me-box {
    top: 67%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.6;
    transition: all 0.25s;

    &:hover {
      opacity: 1;
    }

    .call-me {
      top: 0%;
    }
  }

  .bg-box {
    top: 13%;
    position: relative;
  }

  .creating-dreams {
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 88.75rem;
    width: 80%;
  }

  .rocket {
    max-width: 45.8333rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.3333rem;
  }

  .bottom-red {
    width: 100%;
    left: 0;
    bottom: 0;
    height: 1.3333rem;
    background: #D73F45;
  }
}

@media screen and (max-width: 1024px) {
  [data-anchor = 'firstPage'] {
    .bg-box {
      top: 21%;
    }
  }

  .js-preview-img-bc.single {
    .expand {
      visibility: hidden;
    }
  }
}

@media screen and (max-width: 768px) {
  #main-content .section {
    padding-top: 3rem;
  }

  [data-anchor = 'firstPage'] {
    .bg-box {
      margin-top: 7.5rem;
    }
  }
}

@media screen and (max-width: 767px) {
  [data-anchor = 'firstPage'] {
    .bg-box {
      margin-top: 1.5rem;
    }
  }

  #fp-nav {
    display: none !important;
  }

  .view.ppt {
    #fp-nav {
      display: block !important;
    }
  }
}

[data-anchor = 'secondPage'] {
  color: #fff;

  .swiper-nav {
    margin: 2.2917rem 0 1.875rem;
    text-align: center;

    .item {
      padding: 0 2rem;
      line-height: 0.8;
      opacity: 0.6;
      transition: opacity 0.25s;

      &.active {
        opacity: 0.9;
      }

      &:hover {
        opacity: 0.9;
      }
    }

    .item + .item {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        height: 0.8em;
        width: 1px;
        background: rgba(255, 255, 255, 0.6);
        transform: translateY(-50%);
      }
    }
  }

  .full-page-slide-wrapper {
    height: calc(100% - 15rem);

    .service-wrapper {
      max-width: 82rem;
      margin: 0 auto;
      width: 100%;
      height: 100%;

      .swiper-container {
        width: 100%;
        height: 100%;
      }

      .swiper-slide {
        cursor: move;
        padding: 0 2rem;
        justify-content: center;
        align-items: center;
        display: flex;

        .swiper-item {
          width: 100%;
          height: 100%;
          padding: 0 0 0 2rem;

          .img-box {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: center;

            .img {
              height: auto;
            }
          }

          .desc {
            padding-left: 5rem;

            .mark {
              margin: 0.8333rem 0 0.4167rem;
            }
          }
        }

        .mobile-demo-wrapper {
          padding: 0 6.7833rem;

          .img-box {
            width: 19%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  [data-anchor = 'secondPage'] {
    .service-wrapper {
      .swiper-slide {
        .swiper-item {
          .img-box {
            width: 52%;
          }

          .desc {
            padding-left: 2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  [data-anchor = 'secondPage'] {
    .swiper-nav {
      font-size: 14px;

      .item {
        padding: 0 1rem;
      }
    }

    .service-wrapper {
      height: 85%;

      .swiper-container {
        height: 100%;
      }

      .swiper-slide {
        .swiper-item {
          width: 90%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .img-box {
            width: 85% !important;
          }

          .desc {
            padding-left: 0 !important;
            margin-top: 40px;

            h3 {
              font-size: 16px;
            }

            p {
              font-size: 14px;
            }
          }
        }
      }

      .mobile-demo-wrapper {
        padding: 0 !important;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .img-box {
          width: 30% !important;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  [data-anchor = 'secondPage'] {
    .swiper-nav {
      font-size: 12px;

      .item {
        padding: 0 0.5rem;
      }
    }

    .service-wrapper {
      height: 85%;

      .swiper-container {
        height: 100%;
      }

      .swiper-slide {
        .swiper-item {
          padding: 0 !important;
          width: 90%;
          display: flex;
          align-items: center;

          .desc {
            margin-top: 40px;
            text-align: center;
          }
        }

        .mobile-demo-wrapper {
          .img-box {
            width: 40% !important;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

[data-anchor = '3rdPage'] {
  &.section {
    padding-bottom: 3rem;
  }

  .qr-code-max-wrap {
    width: 130px;
    height: 130px;
    position: relative;
  }

  .qrcode-wrap, .qrcode-wrap-m {
    position: relative;
  }

  .qr-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    z-index: 999;
  }

  .theme-btn-wrapper.look-more {
    position: absolute;
    bottom: 4rem;
    width: 100%;
    opacity: 0.6 !important;
    transition: all 0.25s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    &:hover {
      opacity: 1 !important;
    }
  }

  .demo-max-wrapper {
    width: 100%;
    height: calc(100% - 7rem - 3em);
  }

  .demo-show-wrapper {
    width: 85%;

    .demo-show-box {
      .demo-show {
        width: 32%;
        height: 16.5vw;
        overflow: hidden;
        box-shadow: 4px 6px 13px 0px rgba(164, 164, 164, 0.1);

        &:hover {
          .demo-icon-wrapper {
            opacity: 1;
          }
        }

        .img-box {
          width: 100%;
          height: 100%;
        }

        .demo-desc {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.3);
          padding: 0.5em 0 0.5em 1.5em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transition: all 0.25s;
        }
      }

      .demo-icon-wrapper {
        opacity: 0;
        height: 100%;
        top: 0;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        user-select: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon {
          transform: scale(0.5);
        }

        .demo-desc-insert {
          margin-top: -1.2rem;
        }

        .qrcode-wrap, .qrcode-wrap-m {
          width: 140px;
          height: 140px;
        }
      }
    }
  }

  .look-more {
    margin-top: 6rem;
  }
}

// iphoneX、iphoneXs
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  [data-anchor = '3rdPage'] {
    .theme-btn-wrapper.look-more {
      bottom: 10rem;
    }
  }
}

// iphone Xs Max
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  [data-anchor = '3rdPage'] {
    .theme-btn-wrapper.look-more {
      bottom: 10rem;
    }
  }
}

// iphone XR
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  [data-anchor = '3rdPage'] {
    .theme-btn-wrapper.look-more {
      bottom: 10rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  [data-anchor = '3rdPage'] {
    .demo-show-wrapper {
      width: 90%;
      height: 36%;

      .demo-show-box {
        .demo-show {
          width: 32%;

          .demo-desc {
            .title {
              font-size: 16px;
            }

            .category {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  [data-anchor = '3rdPage'] {
    .demo-show-wrapper {
      width: 85%;
      height: 78%;

      .demo-show-box {
        flex-direction: column;
        height: 100%;

        .demo-show {
          height: 26vw;
          width: 90%;
          margin: 0 auto;
          margin-bottom: 20px;
        }
      }
    }

    .look-more {
      bottom: 4rem;
    }
  }
}

@media screen and (max-width: 767px) {
  [data-anchor = '3rdPage'] {
    .demo-max-wrapper {
      width: 100%;
      height: calc(100% - 7rem - 4rem);
    }

    .demo-show-wrapper {
      height: auto;
      width: 100%;

      .swiper-container-demo {
        width: 100%;
      }

      .demo-icon-wrapper {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;

        .icon {
          width: 50px;
        }
      }
    }

    .demo-show {
      height: 51vw;
      overflow: hidden;

      .img-box {
        border-radius: 4px 4px 0 0;
        overflow: hidden;
      }

      .demo-desc {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.3);
        padding: 0.5em 0 0.5em 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .look-more {
      bottom: 3rem;
      height: 3rem;

      .theme-btn.subtheme-btn {
        transform: translateY(1rem);
      }
    }
  }
}

[data-anchor = '4thPage'] {
  &:after {
    content: '';
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%) scaleX(1.15);
    width: 0;
    height: 0;
    border: 1.75rem solid transparent;
    border-top-color: #fff;
  }

  .soluction-box {
    width: 100%;
    height: calc(100% - 7.5rem);
  }

  .soluction {
    max-width: 106.25rem;
    width: 88%;
    height: 30rem;

    .soluction-item {
      background: #fff;
      width: 24.1%;
      height: 47%;
    }
  }
}

@media screen and (max-width: 1024px) {
  [data-anchor = '4thPage'] {
    .soluction {
      height: 24.4rem;

      .soluction-item {
        background: #fff;
        width: 24%;
        height: 47%;
        margin: 0 0.2rem;
      }
    }
  }

  .swiper-button-prev, .swiper-button-next {
    transform: scale(0.5) !important;
  }
}

@media screen and (max-width: 768px) {
  [data-anchor = '4thPage'] {
    &:after {
      border: 0.875rem solid transparent;
      border-top-color: #fff;
    }

    .soluction {
      width: 100%;
      height: 80%;

      .soluction-item {
        width: 47%;
        height: 22.8%;
      }
    }
  }
}

[data-anchor = '5thPage'] {
  .wx-prod-wrapper {
    max-width: 93.75rem;
    height: calc(100% - 7.5rem);
    width: 80%;
    overflow: hidden;
    border: 1px solid #EEF4F4;

    .wx-prod-item {
      height: 10rem;
      box-shadow: inset -1px -1px 1px -1px #ccc;
      background: #fff;

      &:hover {
        background: #2d313e;
        transition: all 0.25s;

        .icon {
          filter: brightness(100);
        }

        span {
          color: #fff;
        }
      }

      .icon {
        max-width: 2.2rem;
        margin-bottom: 1.25rem;
      }

      span {
        color: #666;
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  [data-anchor = '5thPage'] {
    .wx-prod-wrapper {
      height: 90%;
      width: 90%;
    }
  }
}

@media screen and (max-width: 1024px) {
  [data-anchor = '5thPage'] {
    .wx-prod-wrapper {
      width: 90%;

      .wx-prod-item {
        height: 10.5rem !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  [data-anchor = '5thPage'] {
    .wx-prod-wrapper {
      width: 85%;

      .wx-prod-item {
        height: 11rem !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  [data-anchor = '5thPage'] {
    .wx-prod-wrapper {
      height: 90%;
      width: 78%;

      .wx-prod-item {
        height: 6.2rem !important;

        .icon {
          width: 18px;
          margin-bottom: 0.5rem;
        }

        span {
          font-size: 12px !important;
        }
      }
    }
  }
}

[data-anchor = '6thPage'] {
  .bc-img-wrapper {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .bc-left-top {
      top: 0;
      left: 0;
      width: 100%;
    }

    .bc-right-bottom {
      right: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .block-wrapper {
    left: 0;
    top: 0;
    width: 100%;
    transform-origin: top right;
    transform: scale(0.85);

    .block-bc {
      top: 0;
      right: 0;
      width: 100%;
    }

    .block-item {
      &.active {
        .default {
          display: none;
        }

        .active {
          display: block;
        }
      }

      &:hover {
        .default {
          display: none;
        }

        .active {
          display: block;
        }
      }

      .active {
        display: none;
      }
    }

    .block-publish-box {
      top: 20%;
      right: 49%;
      width: 19%;

      .block-publish {
        width: 100%;
      }
    }

    .block-market-box {
      top: 22%;
      left: 8%;
      width: 14%;

      .block-market {
        width: 100%;
      }
    }

    .block-wallet-box {
      top: 72%;
      left: -8%;
      width: 18%;

      .block-wallet {
        width: 100%;
      }
    }

    .block-game-box {
      top: 71%;
      left: 19%;
      width: 16%;

      .block-game {
        width: 100%;
      }
    }

    .block-desc-box {
      bottom: -5%;
      right: 13%;
      width: 44%;
      height: 30%;

      .block-desc {
        top: 0;
        left: 0;
        width: 100%;
      }

      .block-desc-text {
        top: 14%;
        left: 9%;
        width: 15%;
        z-index: 2;
      }

      .desc-text {
        width: 65%;
        top: 50%;
        left: 50%;
        color: #fff;
        z-index: 2;
        transform: translate(-46%, -22%) rotate(-3deg);
        display: flex;
        justify-content: center;
        font-size: 1.2rem;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  [data-anchor = '6thPage'] {
    .block-wrapper {
      transform: scale(1);

      .block-market-box {
        top: 20%;
        right: 9%;
        left: auto;
        width: 28%;
      }

      .block-publish-box {
        top: 28%;
        left: 9%;
        width: 34%;
      }

      .block-wallet-box {
        top: 52%;
        left: 40%;
        width: 30%;
      }

      .block-game-box {
        top: 62%;
        left: 7%;
        width: 28%;
      }

      .block-desc-box {
        bottom: -10%;
        right: 2%;
        width: 83%;
        height: 30%;

        .block-desc {
          transform: rotate(3deg);
        }

        .block-desc-text {
          top: 3.5%;
          left: 64%;
          width: 24%;
          transform: rotate(13deg);
        }

        .desc-text {
          width: 85%;
          font-size: 12px;
          transform: translate(-48%, -5%) rotate(-7deg);
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) and (min-width: 768px) {
  [data-anchor = '6thPage'] {
    .block-wrapper {
      transform-origin: left top;
      transform: scale(0.92);

      .block-market-box {
        top: 20%;
        right: 9%;
        left: auto;
        width: 28%;
      }

      .block-publish-box {
        top: 28%;
        left: 9%;
        width: 34%;
      }

      .block-wallet-box {
        top: 52%;
        left: 40%;
        width: 30%;
      }

      .block-game-box {
        top: 62%;
        left: 7%;
        width: 28%;
      }

      .block-desc-box {
        bottom: -6%;
        right: -4%;
        width: 70%;
        height: 30%;

        .block-desc {
          transform: rotate(3deg);
        }

        .block-desc-text {
          top: 3.5%;
          left: 64%;
          width: 24%;
          transform: rotate(13deg);
        }

        .desc-text {
          width: 85%;
          font-size: 18px;
          transform: translate(-47%, -36%) rotate(-7deg);
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 1001px) {
  [data-anchor = '6thPage'] {
    .bc-img-wrapper .bc-right-bottom {
      width: auto;
      height: 45%;
    }

    .block-wrapper {
      height: 100%;
      width: auto;
      left: auto;
      transform-origin: right top;
      transform: scale(0.92);

      .block-bc {
        width: auto;
        height: 100%;
      }

      .block-market-box {
        top: 25%;
        right: 22%;
        left: auto;
        width: 21%;
      }

      .block-publish-box {
        top: 34%;
        left: 16%;
        width: 25%;
      }

      .block-wallet-box {
        top: 60%;
        left: 38%;
        width: 25%;
      }

      .block-game-box {
        top: 72%;
        left: 10%;
        width: 20%;
      }

      .block-desc-box {
        bottom: 2%;
        right: -27%;
        width: 61%;
        height: 30%;

        .block-desc {
          transform: rotate(3deg);
        }

        .block-desc-text {
          top: 4%;
          left: 64%;
          width: 24%;
          transform: rotate(14deg);
        }

        .desc-text {
          width: 85%;
          font-size: 17px;
          transform: translate(-47%, -26%) rotate(-7deg);
        }
      }
    }
  }
}

[data-anchor = '7thPage'] {
  overflow: hidden;

  .co-max-wrapper {
    width: 100%;
    height: calc(72% - 7rem);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .co-wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    .co-box {
      display: flex;

      .co-item {
        width: 15.3125rem;
        border: 1px solid #ddd;
        margin: 0.8rem 0.5rem 0.4167rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          .co-icon {
            opacity: 0.7;
          }
        }

        .co-icon {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .co-wrapper {
    flex-direction: column;

    .co-box {
      margin: 0;

      .co-item {
        width: 20rem !important;
        margin: 1rem 1rem !important;

        a {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .co-max-wrapper {
    height: calc(100% - 21rem - 65px) !important;

    .co-box {
      width: 77% !important;
      display: flex;
      justify-content: center;
    }

    .co-wrapper {
      width: 100%;

      .co-item {
        width: 38% !important;
        margin: 0.5rem 0.4rem !important;
      }
    }
  }
}
