.footer
  bottom 0
  left 0
  right 0
  height 28%
  max-height 14rem
  background #2d313e
  z-index 0

  .footer-desc-wrapper
    max-width 70rem
    width 90%
    margin 2.4167rem auto 0

    .desc-box
      &.main
        display flex
        flex-direction column

      .contact.tel
        margin-right 10rem

      .icon-box
        margin 5px 0.8333rem 0 0
        width 14px
        height 14px

      .info
        margin-top 0.2rem

      .main-business
        margin-top 1rem
        line-height 1em
        flex 1
        display flex
        justify-content space-evenly

        .item
          margin-right 3.125rem
          line-height 1em

          .text
            margin-top 1rem
            line-height 1em
            opacity 0.6
            cursor pointer

            &:hover
              opacity 1

    .top
      margin-top 1.0833rem

  .copyright
    left 0
    right 0
    bottom 0
    height 2.6667rem
    font-size 0.8333rem
    background #202431
    color rgba(255, 255, 255, 0.3)

@media screen and (max-width: 1200px)
  .footer-desc-wrapper
    .title
      font-size 0.9167rem

    .contact.tel
      margin-right 10rem

    .main-business
      font-size 0.75rem

    .tel-wrapper
      font-size 0.75rem

@media screen and (max-width: 768px)
  .footer
    max-height 21rem

  .footer-desc-wrapper
    overflow hidden
    margin 1.1rem auto 9.5rem !important

    .contact.tel
      margin-right 0 !important

    .main-business, .main-business, .tel-wrapper
      flex-direction column

      .item
        margin-top 0
        display flex
        flex-direction column
        justify-content space-evenly

        .text
          margin-top 1rem !important

    .title
      font-size 14px

    .main-business
      font-size 13px

    .tel-wrapper
      font-size 13px

    .contact
      justify-content space-evenly

  .copyright
    height 3rem !important
    font-size 13px

@media screen and (max-width: 767px)
  .footer
    height 50%

    .footer-desc-wrapper
      margin 10px auto 0 !important

      .title
        font-size 14px

      .desc-box
        .main-business
          margin-top 0
          font-size 12px

          .item
            .text
              margin-top 5px

        .icon
          width 100%

      .tel-info
        font-size 12px

    .top
      margin-top 10px !important
