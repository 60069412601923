section.not-found-wrapper
  height 100vh
  background #2E313F

  .bg-canvas
    width 100%
    height 100%

  .not-found-box
    max-width 33rem
    width 90%
    top 42%
    left 50%
    transform translate(-50%, -50%)
    z-index 2

    .not-found
      width 100%
      display block

  .not-found-desc-box
    width 100%
    left 0
    bottom -8rem
    text-align center

    .not-found-desc
      font-size 1rem
      font-weight 400
      letter-spacing 3px

    .back-home
      margin 2.8125rem auto 0
      width 12.25rem
      height 3.125rem
      border solid 1px #ffffff
      display flex
      align-items center
      justify-content center

  .not-found-bottom
    bottom 0
    left 50%
    transform translateX(-50%)
    max-width 60rem
    width 100%
    z-index 2

@media screen and (max-width: 767px) and (min-width: 600px)
  section.not-found-wrapper
    .not-found-box
      max-width 21rem

    .not-found-bottom
      max-width 31rem