@media screen and (max-width: 767px) and (orientation: portrait)
  #lock
    display none !important

@media screen and (max-width: 767px) and (orientation: landscape)
  #main-content
    display none !important

@media screen and (min-width: 768px)
  #lock
    display none !important

#lock
  position absolute
  width 100%
  height 100%
  left 0
  top 0
  z-index 9999

.lock
  position absolute
  left 50%
  top 50%
  width 80%
  transform translate(-50%, -50%)

.lock .lock-tip
  position relative
  margin-bottom 10px
  transform scale(0.7)
