
#superContainer
  height 100%
  position relative
  /* Touch detection for Windows 8 */
  -ms-touch-action none
  /* IE 11 on Windows Phone 8.1 */
  touch-action none

.fp-section
  position relative
  box-sizing border-box

.fp-slide
  float left

.fp-slide, .fp-slidesContainer
  height 100%
  display block

.fp-slides
  z-index 1
  height 100%
  overflow hidden
  position relative
  transition all 0.3s ease-out

.fp-section.fp-table, .fp-slide.fp-table
  display table
  table-layout fixed
  width 100%

.fp-tableCell
  display table-cell
  vertical-align middle
  width 100%
  height 100%

.fp-slidesContainer
  float left
  position relative

.fp-controlArrow
  position absolute
  z-index 4
  top 50%
  cursor pointer
  width 0
  height 0
  border-style solid
  margin-top -38px

.fp-controlArrow.fp-prev
  left 15px
  width 0
  border-width 38.5px 34px 38.5px 0
  border-color transparent #fff transparent transparent

.fp-controlArrow.fp-next
  right 15px
  border-width 38.5px 0 38.5px 34px
  border-color transparent transparent transparent #fff

.fp-scrollable
  overflow scroll

.fp-notransition
  -webkit-transition none !important
  transition none !important

#fp-nav
  position fixed
  z-index 100
  margin-top -32px
  top 50%
  opacity 1

#fp-nav.right
  right 17px

#fp-nav.left
  left 17px

.fp-slidesNav
  position absolute
  z-index 4
  left 50%
  opacity 1

.fp-slidesNav.bottom
  bottom 17px

.fp-slidesNav.top
  top 17px

#fp-nav ul, .fp-slidesNav ul
  margin 0
  padding 0

#fp-nav ul li, .fp-slidesNav ul li
  display block
  width 21px
  height 21px
  margin 7px
  position relative

.fp-slidesNav ul li
  display inline-block

#fp-nav ul li a, .fp-slidesNav ul li a
  display block
  position relative
  z-index 1
  width 100%
  height 100%
  cursor pointer
  text-decoration none
  display flex
  align-items center
  justify-content center

#fp-nav ul li a.active span, .fp-slidesNav ul li a.active span
  background #fff
  box-shadow 0 0 0 4px rgba(255, 255, 255, 0.5)

#fp-nav ul li a.active.subBg span, .fp-slidesNav ul li a.active.subBg span
  background #bbb
  box-shadow 0 0 0 4px rgba(0, 0, 0, 0.1)

#fp-nav ul li a span, .fp-slidesNav ul li a span
  width 13px
  height 13px
  background rgba(255, 255, 255, 0.5)
  border-radius 50%
  position relative
  z-index 1

#fp-nav ul li a.subBg span, .fp-slidesNav ul li a.subBg span
  background rgba(0, 0, 0, 0.1)

#fp-nav ul li .fp-tooltip
  position absolute
  top -2px
  color #fff
  font-size 14px
  font-family arial, helvetica, sans-serif
  white-space nowrap
  max-width 220px
  overflow hidden
  display block
  opacity 0
  width 0

#fp-nav ul li:hover .fp-tooltip
  -webkit-transition opacity 0.2s ease-in
  transition opacity 0.2s ease-in
  width auto
  opacity 1

#fp-nav ul li .fp-tooltip.right
  right 27px
  top 3px

#fp-nav ul li .fp-tooltip.left
  left 27px
  top 3px
