/**
  list 样式
*/
.common-header
  .nav-max-wrapper
    background #ffffff
    justify-content center
    display flex
    align-items center
    height 60px

    .nav-wrapper
      margin 0 auto

  .bg-canvas
    z-index 0

  .canvas-box
    height 140px
    display flex
    align-items center
    justify-content center

    .topic-box
      z-index 99

.view
  min-height 100vh

  &.concat
    .section
      max-width 88.75rem
      width 93%
      margin 0 auto
      padding 30px 0 0
      min-height calc(100vh - 60px - 140px - 3rem)

      #map
        width 99.5%
        height 45vh
        border-radius 4px

      .info-wrapper
        height 40%
        padding 2rem 0 0
        display none

        .topic
          border-bottom 1px solid #eeeeee
          padding-bottom 0.5rem
          margin-bottom 0.3rem

      .card-wrap
        padding 20px 0

        .topic
          font-size 16px

        .card-list
          display flex
          align-items center
          flex-wrap wrap

          .card-item
            display flex
            height 140px
            width 32%
            box-shadow 0 0 10px 0 rgba(0, 0, 0, 0.2)
            margin 20px 24px 0 0
            border-radius 4px
            overflow hidden

            &:nth-child(3n)
              margin-right 0

            .left-box
              width 140px
              height 100%
              font-size 0

              .avatar
                width 100%
                height 100%
                object-fit cover

            .right-box
              padding 15px
              flex 1

              .name-box
                font-size 18px

              .desc-box
                display flex
                align-items flex-end
                justify-content space-between

                .tel-email-box
                  font-size 14px
                  color #666
                  flex 1
                  display flex
                  flex-direction column

                  .a
                    &:hover
                      color #319D55

                  .office
                    margin 15px 0 7px

                .qrcode-box
                  width 70px
                  height 70px

                  .qrcode
                    width 100%
                    height 100%

  header
    background #2d313e

    .nav-wrapper .logo
      transform scale(0.25)

    .m-nav-max-wrapper
      background #fff
      padding 0 0 0 1rem
      border-bottom 1px solid #eee

      .m-nav
        background #fff
        padding 0.4167rem 1.0417rem
        position absolute
        top 7rem
        left 0
        width 100vw
        box-sizing border-box
        overflow hidden
        z-index 2
        background #fff
        font-size 16px
        box-shadow 6px 10px 40px 0px rgba(163, 163, 163, 0.1)
        opacity 0
        transition all 0.5s
        visibility hidden
        z-index 99999

        &.active
          top 5.7rem
          opacity 1
          visibility visible

        .nav-item
          height 2em
          margin 1rem 0

      .m-nav-wrapper
        position relative !important

    .bg-canvas
      width 100%
      height 100%
      top 0
      left 0

    .m-logo-box
      z-index 99999
      left 0.2rem
      top 0.2rem

  section
    max-width 66.6667rem
    min-height calc(100vh - 200px - 3rem - 5rem - 1px)
    width 93%
    margin 2.5rem auto

    .topic-box
      .item
        font-size 1.2rem
        padding 0 0.9rem
        cursor pointer
        transition all 0.25s

        &:hover
          color #333

        &.active
          color #333

      .item+.item
        position relative

        &:before
          content ''
          position absolute
          left 0
          top 50%
          transform translateY(-50%)
          height 0.8em
          width 1px
          background #ddd

    .demo-show
      width 100%
      height 25vw
      margin 2.5rem 0
      border-radius 4px
      overflow hidden
      box-shadow 6px 10px 10px 0px rgba(163, 163, 163, 0.2)

      &:hover
        .demo-icon-wrapper
          opacity 1
          transition all 0.25s

      .demo-icon-wrapper
        opacity 0
        left 0
        top 0
        width 100%
        height 100%
        background rgba(0, 0, 0, 0.3)
        border-radius 4px
        z-index 2

        .icon
          top 50%
          left 50%
          transform translate(-50%, -50%) scale(0.5)

      .img-box
        width 100%
        height 100%
        overflow hidden
        border-radius 4px 4px 0 0
        position relative

        .img
          position absolute
          top 0
          left 0
          width 100%
          object-fit unset
          height auto !important

      .demo-desc
        position absolute
        bottom 0
        left 0
        right 0
        background rgba(0, 0, 0, 0.3)
        padding 0.5em 0 0.5em 1.5em
        display flex
        flex-direction column
        justify-content center

  .copyright
    height 3rem
    background #2d313e
    color rgba(255, 255, 255, 0.3)

.preview-img-wrapper
  display flex
  flex-direction column
  align-items flex-end

  .preview-img-box
    flex 1

.m-play-wrap
  width 200px
  height 40px
  border 1px solid #ffffff
  border-radius 10px
  line-height 40px
  text-align center
  color #fff
  margin 0 20px 10px 0
  display block
  font-size 16px

.is-play-wrap
  position absolute
  bottom 30px
  right -3.3rem
  color #fff
  z-index 2
  display flex
  flex-direction column
  align-items flex-end
  justify-content flex-end

  .qrcode-max-wrap
    width 140px
    height 140px
    position relative
    display flex
    justify-content flex-end

    .qrcode-wrap, .qrcode-wrap-m
      width 140px
      height 140px
      padding 10px
      background #fff

    .admin-qrcode
      width 140px
      height 140px
      padding 10px
      background #fff

      .admin-qrcode-img
        width 100%
        height 100%

    .qr-icon
      width 20px
      height 20px
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)

  .game-name
    text-align right
    font-size 14px
    margin-top 3px
    text-shadow 0 1px rgba(0, 0, 0, 0.1), 1px 0 rgba(0, 0, 0, 0.1), -1px 0 rgba(0, 0, 0, 0.1), 0 -1px rgba(0, 0, 0, 0.1)

  .game-desc
    text-align right
    font-size 12px
    opacity 0.6
    margin 3px 0 10px
    text-shadow 0 1px rgba(0, 0, 0, 0.1), 1px 0 rgba(0, 0, 0, 0.1), -1px 0 rgba(0, 0, 0, 0.1), 0 -1px rgba(0, 0, 0, 0.1)

  .go-look
    font-size 14px
    cursor pointer
    width 120px
    height 2rem
    background rgba(255, 255, 255, 0.2)
    border 1px solid #fff
    color rgba(255, 255, 255, 0.8)
    display flex
    align-items center
    justify-content center
    text-shadow 0 1px rgba(0, 0, 0, 0.1), 1px 0 rgba(0, 0, 0, 0.1), -1px 0 rgba(0, 0, 0, 0.1), 0 -1px rgba(0, 0, 0, 0.1)

.js-preview-img-bc.single
  .preview-img-wrapper
    height calc(100vh - 10px)

    // overflow-y auto
    .preview-img-box
      height 100%
      overflow-y auto
      font-size 0

    .is-play-wrap
      position fixed
      right 7rem

@media screen and (min-width 1301px)
  .m-play-wrap
    visibility hidden !important

  .js-preview-img-bc.single
    .m-play-wrap
      display none !important

@media screen and (max-width 1300px)
  .view
    section
      .demo-show
        height 30vw

    .m-play-wrap
      visibility visible

  .is-play-wrap
    visibility hidden !important

  .js-preview-img-bc .preview-img-wrapper
    padding-top 2rem

  .js-preview-img-bc .preview-img-wrapper .close
    top 2rem

  .view
    min-height 100vh

    &.concat
      .section
        .card-wrap
          .card-list
            .card-item
              width 48%

              &:nth-child(3n)
                margin-right 24px

              &:nth-child(2n)
                margin-right 0

@media screen and (max-width 767px)
  .view
    &.concat
      .section
        min-height calc(100vh - 56px - 3rem)

        .card-wrap
          .card-list
            margin-right 0

            .card-item
              width 100%
              margin-right 0

              &:nth-child(3n)
                margin-right 0

  .view
    header .m-logo-box
      width 100px
      height 25px

      .m-logo
        transform scale(0.2)
        transform-origin left top

    .footer
      height 26rem

    .main-business
      flex-direction column

    .copyright
      height 20px

    section
      min-height calc(100vh - 56px - 3rem - 5rem)

      .demo-show
        height 50vw

.ipad-logo
  width 100px