.container
  margin-left auto
  margin-right auto
  padding-left 15px
  padding-right 15px

.show-md-block
  display none !important

.show-lg-block
  display none !important

.container-fluid
  margin-left auto
  margin-right auto
  padding-left 15px
  padding-right 15px

.row
  margin-left -15px
  margin-right -15px

.row:before, .row:after
  content ''
  display table
  clear both

.hide-xs
  display none !important

.show-xs-block
  display block !important

.show-xs-inline
  display inline !important

.show-xs-inline-block
  display inline-block !important

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12
  position relative
  min-height 1px
  padding-right 15px
  padding-left 15px

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12
  float left

.col-xs-1
  width 8.33333333%

.col-xs-2
  width 16.66666667%

.col-xs-3
  width 25%

.col-xs-4
  width 33.33333333%

.col-xs-5
  width 41.66666667%

.col-xs-6
  width 50%

.col-xs-7
  width 58.33333333%

.col-xs-8
  width 66.66666667%

.col-xs-9
  width 75%

.col-xs-10
  width 83.33333333%

.col-xs-11
  width 91.66666667%

.col-xs-12
  width 100%

.col-xs-offset-1
  margin-left 8.33333333%

.col-xs-offset-2
  margin-left 16.66666667%

.col-xs-offset-3
  margin-left 25%

.col-xs-offset-4
  margin-left 33.33333333%

.col-xs-offset-5
  margin-left 41.66666667%

.col-xs-offset-6
  margin-left 50%

.col-xs-offset-7
  margin-left 58.33333333%

.col-xs-offset-8
  margin-left 66.66666667%

.col-xs-offset-9
  margin-left 75%

.col-xs-offset-10
  margin-left 83.33333333%

.col-xs-offset-11
  margin-left 91.66666667%

.col-xs-offset-12
  margin-left 100%

@media screen and (min-width: 768px)
  .hide-xs
    display block !important

  .show-xs-block
    display none !important

  .container
    width 750px

  .hide-sm
    display none !important

  .show-sm-block
    display block !important

  .show-sm-inline
    display inline !important

  .show-sm-inline-block
    display inline-block !important

  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12
    float left

  .col-sm-1
    width 8.33333333%

  .col-sm-2
    width 16.66666667%

  .col-sm-3
    width 25%

  .col-sm-4
    width 33.33333333%

  .col-sm-5
    width 41.66666667%

  .col-sm-6
    width 50%

  .col-sm-7
    width 58.33333333%

  .col-sm-8
    width 66.66666667%

  .col-sm-9
    width 75%

  .col-sm-10
    width 83.33333333%

  .col-sm-11
    width 91.66666667%

  .col-sm-12
    width 100%

  .col-sm-offset-1
    margin-left 8.33333333%

  .col-sm-offset-2
    margin-left 16.66666667%

  .col-sm-offset-3
    margin-left 25%

  .col-sm-offset-4
    margin-left 33.33333333%

  .col-sm-offset-5
    margin-left 41.66666667%

  .col-sm-offset-6
    margin-left 50%

  .col-sm-offset-7
    margin-left 58.33333333%

  .col-sm-offset-8
    margin-left 66.66666667%

  .col-sm-offset-9
    margin-left 75%

  .col-sm-offset-10
    margin-left 83.33333333%

  .col-sm-offset-11
    margin-left 91.66666667%

  .col-sm-offset-12
    margin-left 100%

@media screen and (min-width: 992px)
  .container
    width 970px

  .show-xs-block
    display none !important

  .hide-xs
    display block !important

  .hide-md
    display none !important

  .show-md-block
    display block !important

  .show-md-inline
    display inline !important

  .show-md-inline-block
    display inline-block !important

  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12
    float left

  .col-md-1
    width 8.33333333%

  .col-md-2
    width 16.66666667%

  .col-md-3
    width 25%

  .col-md-4
    width 33.33333333%

  .col-md-5
    width 41.66666667%

  .col-md-6
    width 50%

  .col-md-7
    width 58.33333333%

  .col-md-8
    width 66.66666667%

  .col-md-9
    width 75%

  .col-md-10
    width 83.33333333%

  .col-md-11
    width 91.66666667%

  .col-md-12
    width 100%

  .col-md-offset-1
    margin-left 8.33333333%

  .col-md-offset-2
    margin-left 16.66666667%

  .col-md-offset-3
    margin-left 25%

  .col-md-offset-4
    margin-left 33.33333333%

  .col-md-offset-5
    margin-left 41.66666667%

  .col-md-offset-6
    margin-left 50%

  .col-md-offset-7
    margin-left 58.33333333%

  .col-md-offset-8
    margin-left 66.66666667%

  .col-md-offset-9
    margin-left 75%

  .col-md-offset-10
    margin-left 83.33333333%

  .col-md-offset-11
    margin-left 91.66666667%

  .col-md-offset-12
    margin-left 100%

@media screen and (min-width: 1200px)
  .container
    width 1170px

  .show-xs-block
    display none !important

  .hide-xs
    display block !important

  .show-md-block
    display none !important

  .hide-lg
    display none !important

  .show-lg-block
    display block !important

  .show-lg-inline
    display inline !important

  .show-lg-inline-block
    display inline-block !important

  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12
    float left

  .col-lg-1
    width 8.33333333%

  .col-lg-2
    width 16.66666667%

  .col-lg-3
    width 25%

  .col-lg-4
    width 33.33333333%

  .col-lg-5
    width 41.66666667%

  .col-lg-6
    width 50%

  .col-lg-7
    width 58.33333333%

  .col-lg-8
    width 66.66666667%

  .col-lg-9
    width 75%

  .col-lg-10
    width 83.33333333%

  .col-lg-11
    width 91.66666667%

  .col-lg-12
    width 100%

  .col-lg-offset-1
    margin-left 8.33333333%

  .col-lg-offset-2
    margin-left 16.66666667%

  .col-lg-offset-3
    margin-left 25%

  .col-lg-offset-4
    margin-left 33.33333333%

  .col-lg-offset-5
    margin-left 41.66666667%

  .col-lg-offset-6
    margin-left 50%

  .col-lg-offset-7
    margin-left 58.33333333%

  .col-lg-offset-8
    margin-left 66.66666667%

  .col-lg-offset-9
    margin-left 75%

  .col-lg-offset-10
    margin-left 83.33333333%

  .col-lg-offset-11
    margin-left 91.66666667%

  .col-lg-offset-12
    margin-left 100%