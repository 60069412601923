
@import './variable.styl'

*
  touch-action pan-y

@keyframes scrollAni
  0%
    bottom 30px

  50%
    bottom 40px

  100%
    bottom 30px

.can-scroll
  bottom 30px
  left 50%
  transform translateX(-50%)
  background rgba(0, 0, 0, 0.5)
  border-radius 4px
  padding 10px
  z-index 2
  animation scrollAni 1.5s infinite

  .scroll-text
    font-size 1rem

  .arrow
    font-size 18px

a.contact-us:hover
  color #309D55
  transition all 0.25s

.tel-info
  .op5:hover
    opacity 1
    transition all 0.25s

.img-box
  font-size 0
  display block

  .img
    width 100%
    height 100%
    object-fit cover

.icon-box
  position relative
  font-size 0

  .icon
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    max-width 100%
    max-height 100%

.theme-btn-wrapper
  width 10em
  height 2.5em
  display flex
  align-items center
  justify-content center

.theme-btn
  border 1px solid #fff
  outline none
  background transparent
  display flex
  align-items center
  justify-content center
  color #fff
  cursor pointer
  width 10em
  height 2.5em
  opacity 0.6
  transition all 0.25s

  &:hover
    opacity 1

  &.subtheme-btn
    border 1px solid #666
    color #333

@media screen and (max-width: 768px)
  .theme-topic-box
    .title
      font-size 24px !important

    .desc
      font-size 14px !important

.theme-topic-box
  display flex
  flex-direction column
  align-items center
  justify-content space-between
  width 26em
  height 7.5rem
  margin 0 auto
  color #fff
  font-size 1rem

  &.sub-theme-topic
    color #333

    .line
      background #ddd

      &:after
        background #2d313e

    .desc
      color #999

  .title
    font-size 2rem
    font-weight normal

  .line
    position relative
    width 100%
    height 1px
    background rgba(255, 255, 255, 0.4)

    &:after
      content ''
      display block
      position absolute
      left 50%
      top 50%
      transform translate(-50%, -50%)
      width 2.3333rem
      height 4px
      background #fff

  .desc
    font-size 1.125rem
    opacity 0.7
    font-weight 300

@media screen and (max-width: 767px)
  .theme-topic-box
    .title
      font-size 20px !important

    .line
      &:after
        height 2px !important

// 定位
[data-position~='relative']
  position relative

[data-position~='absolute']
  position absolute

[data-position~='fixed']
  position fixed

.fb
  font-weight bold

.bdrs
  border-radius $border-radius

.block
  display block

.text-center
  text-align center

/* 颜色定义 */
.bcfff
  background #fff

.bctheme
  background $background-theme

.fctheme
  color $color-theme

.fcaaa
  color #aaa

.fcbbb
  color #bbb

.fc000
  color #000

.fc333
  color #333

.fc444
  color #444

.fc555
  color #555

.fc666
  color #666

.fc777
  color #777

.fc888
  color #888

.fc999
  color #999

.fcfff
  color #fff

/* 字体定义 */
.fs0
  font-size 0

.fs10
  font-size $fs10

.fs12
  font-size $fs12

.mfs12
  font-size $fs12 !important

.fs14
  font-size $fs14

.mfs14
  font-size $fs14 !important

.fs16
  font-size $fs16

.mfs16
  font-size $fs16 !important

.fs17
  font-size $fs17

.mfs17
  font-size $fs17 !important

.fs18
  font-size $fs18

.mfs18
  font-size $fs18 !important

.fs20
  font-size $fs20

.mfs20
  font-size $fs20 !important

.fs21
  font-size $fs21

.mfs21
  font-size $fs21 !important

.fs22
  font-size $fs22

.mfs22
  font-size $fs22 !important

.fs23
  font-size $fs23

.mfs23
  font-size $fs23 !important

.fs24
  font-size $fs24

.mfs24
  font-size $fs24 !important

.fs25
  font-size $fs25

.mfs25
  font-size $fs25 !important

.fs26
  font-size $fs26

.mfs26
  font-size $fs26 !important

.fs28
  font-size $fs28

.mfs28
  font-size $fs28 !important

.fs32
  font-size $fs32

.mfs32
  font-size $fs32 !important

.fs34
  font-size $fs34

.mfs34
  font-size $fs34 !important

.fs30
  font-size $fs30

.mfs30
  font-size $fs30 !important

.fs40
  font-size $fs40

.mfs40
  font-size $fs40 !important

.fs50
  font-size $fs50

.mfs50
  font-size $fs50 !important

.fw100
  font-weight 100

.fw200
  font-weight 200

.fw300
  font-weight 300

.fw400
  font-weight 400

// 透明度
.op0
  opacity 0

.op1
  opacity 0.1

.op2
  opacity 0.2

.op3
  opacity 0.3

.op4
  opacity 0.4

.op5
  opacity 0.5

.op6
  opacity 0.6

.op7
  opacity 0.7

.op8
  opacity 0.8

.op9
  opacity 0.9
