.view.ppt {
  background: #000;
}

.ppt-wrap {
  .ppt-item {
    position: relative;

    .ppt-img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.tips {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tip-text {
    color: #fff !important;
  }
}

.fp-tableCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen-tip {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;
}

@media screen and (orientation: portrait) {
  /* 竖屏 css */
  .screen-tip {
    display: block;
  }
}

@media screen and (orientation: landscape) {
  /* 横屏 css */
  .screen-tip {
    display: none;
  }
}