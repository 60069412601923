.m-nav-wrapper
  position fixed
  left 0
  right 0
  top 0
  z-index 999
  font-size 0.75rem

  .m-nav-bar-box
    margin 0.875rem 0.625rem 0

    .m-nav-bar
      position relative
      transform scale(0.6)
      transform-origin right
      height 34px
      width 40px
      z-index 998

      .list
        transition all 0.5s

      .one
        position absolute
        top 0

        &.on
          transform rotate(45deg) translate(2px, 0)
          transform-origin left top

      .two
        &.on
          opacity 0

      .three
        position absolute
        bottom 0

        &.on
          transform rotate(-45deg)
          transform-origin left bottom

  .m-nav
    padding 0.4167rem 1.0417rem
    position absolute
    top 1.125rem
    right 1.125rem
    left 1.125rem
    z-index 2
    background rgba(45, 49, 62, 0.85)
    font-size 16px
    box-shadow 6px 10px 40px 0px rgba(163, 163, 163, 0.1)
    opacity 0
    transition all 0.5s
    border-radius 4px
    transform scale(0)
    transform-origin right top

    &.active
      transform scale(1)
      opacity 1

    .nav-item
      height 2em
      margin 1rem 0

    a
      opacity 0.9
      font-weight 300
      width 100%
      height 100%
      display flex
      align-items center

.nav-wrapper
  position relative
  max-width 88.75rem
  width 93%
  margin 2.0417rem auto 0

  .logo
    transform-origin left
    transform scale(0.8)

  .nav-item + .nav-item
    margin-left 2rem

  .nav-item
    a
      opacity 0.4

      &.op10
        opacity 1

      &:hover
        opacity 1

@media screen and (max-width: 767px)
  .m-nav-wrapper .m-nav-bar-box
    margin 10px

  .m-nav
    .nav-item
      a
        display block
        width 100%