@media screen and (min-width: 1920px)
  html
    font-size 16px

@media screen and (max-width: 1920px)
  html
    font-size 16px

@media screen and (max-width: 1680px)
  html
    font-size 15.5px

@media screen and (max-width: 1440px)
  html
    font-size 15px

@media screen and (max-width: 1366px)
  html
    font-size 14.5px

@media screen and (max-width: 1280px)
  html
    font-size 14px

@media screen and (max-width: 940px)
  html
    font-size 12px

@media screen and (max-width: 768px)
  html
    font-size 10px
