
[data-flex]
  display flex

  & > [data-cell]
    flex 1

  & > [data-cell*='1/'], & > [data-cell~='flex0']
    flex initial

  & > [data-cell~='flex']
    display flex

// 排列形式
[data-flex~='row']
  flex-direction row

[data-flex~='column']
  flex-direction column

// 水平垂直居中
[data-flex~='center']
  justify-content center
  align-items center

// 可换行
[data-flex~='wrap']
  flex-wrap wrap

/*
 *******************************************************
 主轴对齐方式
*/
[data-flex~='main-start']
  justify-content flex-start

[data-flex~='main-center']
  justify-content center

[data-flex~='main-end']
  justify-content flex-end

/* 均匀排列每个元素,首个元素放置于起点，末尾元素放置于终点 */
[data-flex~='main-between']
  justify-content space-between

/* 均匀排列每个元素,每个元素周围分配相同的空间 */
[data-flex~='main-around']
  justify-content space-around

/* 均匀排列每个元素,每个元素之间的间隔相等 */
[data-flex~='main-evenly']
  justify-content space-evenly

/*
 *******************************************************
 交叉轴对齐方式
*/
[data-flex~='cross-start']
  align-items flex-start

[data-flex~='cross-center']
  align-items center

[data-flex~='cross-end']
  align-items flex-end

[data-flex~='cross-baseline']
  align-items baseline

/* 弹性元素被在侧轴方向被拉伸到与容器相同的高度或宽度 */
[data-flex~='cross-stretch']
  align-items stretch

/*
 *******************************************************
 多轴对齐方式
*/
[data-flex~='start']
  align-content flex-start

[data-flex~='end']
  align-content flex-end

[data-flex~='center']
  align-content center

[data-flex~='between']
  align-content space-between

[data-flex~='around']
  align-content space-around

[data-flex~='stretch']
  align-content stretch

/*
 *******************************************************
 元素自身对齐方式
*/
[data-cell~='start']
  align-self flex-start

[data-cell~='end']
  align-self flex-end

[data-cell~='center']
  align-self center

[data-cell~='baseline']
  align-self baseline

[data-cell~='stretch']
  align-self stretch

/*
 *******************************************************
 固定宽度
*/
[data-cell~='1/2']
  width 50%

[data-cell~='1/3']
  width 33.3333333%

[data-cell~='1/4']
  width 25%

[data-cell~='1/5']
  width 20%

[data-cell~='1/6']
  width 16.6666667%

/*
 *******************************************************
 排序
*/
[data-cell~='order1']
  order 1

[data-cell~='order2']
  order 2

[data-cell~='order3']
  order 3

[data-cell~='order4']
  order 4

[data-cell~='order5']
  order 5

[data-cell~='order6']
  order 6

[data-cell~='order7']
  order 7

[data-cell~='order8']
  order 8

[data-cell~='order9']
  order 9

[data-cell~='order10']
  order 10

/*
 *******************************************************
 响应式
*/
@media (max-width: 768px)
  [data-flex~='full']
    flex-wrap wrap

    & > [data-cell]
      flex-grow 0
      flex-shrink 0
      flex-basis 100%
