.icon-close
  display block
  background-image url('../img/sprite.png')
  background-repeat no-repeat
  background-position -335px -133px
  width 40px
  height 40px

.icon-emaill
  display block
  background-image url('../img/sprite.png')
  background-repeat no-repeat
  background-position -293px -212px
  width 27px
  height 22px

.icon-expand
  display block
  background-image url('../img/sprite.png')
  background-repeat no-repeat
  background-position -293px -133px
  width 40px
  height 40px

.icon-list-logo
  display block
  background-image url('../img/sprite.png')
  background-repeat no-repeat
  background-position 0px 0px
  width 412px
  height 131px

.icon-list
  display block
  background-image url('../img/sprite.png')
  background-repeat no-repeat
  background-position -293px -175px
  width 41px
  height 5px

.icon-logo
  display block
  background-image url('../img/sprite.png')
  background-repeat no-repeat
  background-position -189px -237px
  width 140px
  height 45px

.icon-look-more
  display block
  background-image url('../img/sprite.png')
  background-repeat no-repeat
  background-position -189px -133px
  width 102px
  height 102px

.icon-message
  display block
  background-image url('../img/sprite.png')
  background-repeat no-repeat
  background-position -336px -175px
  width 28px
  height 27px

.icon-portrait
  display block
  background-image url('../img/sprite.png')
  background-repeat no-repeat
  background-position 0px -133px
  width 187px
  height 172px

.icon-scroll-tips
  display block
  background-image url('../img/sprite.png')
  background-repeat no-repeat
  background-position -394px -175px
  width 18px
  height 18px

.icon-tel
  display block
  background-image url('../img/sprite.png')
  background-repeat no-repeat
  background-position -377px -133px
  width 29px
  height 27px

.icon-top
  display block
  background-image url('../img/sprite.png')
  background-repeat no-repeat
  background-position -366px -175px
  width 26px
  height 27px
