@keyframes lds-eclipse
  0%
    transform rotate(0deg)

  50%
    transform rotate(180deg)

  100%
    transform rotate(360deg)

.lds-css
  position fixed
  top 50%
  left 50%
  transform translate(-50%, -50%)

.lds-eclipse
  position relative

.lds-eclipse div
  position absolute
  animation lds-eclipse 1s linear infinite
  width 10rem
  height 10rem
  top 5rem
  left 5rem
  border-radius 50%
  box-shadow 0 4px 0 0 #ccc
  transform-origin 5rem 5.125rem

.lds-eclipse
  width 5rem !important
  height 5rem !important
  transform translate(-50px, -50px) scale(0.4) translate(50px, 50px)