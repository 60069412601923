.target-qrcode-wrap
  height 100%
  background #2A2A2A
  font-size 14px
  color #E4E3D1
  padding 20px 0

  .title
    text-align center
    margin-bottom 10px

  .target-qrcode-wrap-img-box
    width 160px
    margin 0 auto
    height 160px
    padding 10px
    background #fff
    display flex
    align-items center
    justify-content center

    .target-qrcode-wrap-img
      width 100%
      height 100%

    .create-qrcode
      width 100%
      height 100%