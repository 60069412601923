#pagination ul
    list-style none
    padding-left 0
    display flex
    justify-content center

@media screen and (max-width: 500px)
    #pagination ul li
        font-size 12px !important

#pagination ul li
    font-size 14px
    min-width 36px
    min-height 28px
    cursor pointer
    display flex
    align-items center
    justify-content center
    box-sizing border-box
    background-color #ffffff
    color #2d313f
    margin 0 4px
    outline 1px solid #ebebeb
    height 30px
    padding 0 4px

#pagination ul li:hover
    background-color #2d313f
    color #fff
    outline none
    transition all 0.5s

#pagination li.active
    background #2d313f
    color white
    cursor not-allowed

#pagination li.disabled
    cursor not-allowed

#pagination li.totalPage
    background transparent
    cursor default
    border none
    padding 0 6px

#pagination li.totalPage:hover
    transform none
    background-color #ffffff

#pagination li input
    -webkit-appearance none
    background-color #fff
    background-image none
    border 1px solid #dcdfe6
    box-sizing border-box
    color #2d313f
    display inline-block
    font-size inherit
    outline none
    padding 3px 5px
    transition border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)
    width 40px
    height 25px
    margin 0 6px

#pagination li input:focus
    border-color #2d313f

#pagination
    user-select none

input[type=number]
    -moz-appearance textfield

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button
    -webkit-appearance none
    margin 0
